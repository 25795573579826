var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import fuzzySet from 'fuzzyset.js';
import AliasCard from './alias/AliasCard';
import BulkLabelInfo from './BulkLabelInfo';
import ConfigBar from './ConfigBar';
import ScanInformation from '../components/ScanInformation';
import OCRWebcam from '../components/OCRWebcam';
import Error from '../components/Error';
import { getAliasAndAddToQueue, ocrScan, playAliasSoundFromQueue } from '../redux/actions/scan';
import { printBulkLabel } from '../redux/actions/bulkLabel';
import t from '../lib/translate';
import { base64toBlob } from '../lib/format';
import InfoScanForm from './InfoScanForm';
var TRACKING_REFERENCE_PATTERN = /^[a-z æøå A-Z ÆØÅ 0-9 \- ()]*$/;
var INITIAL_STATE = {
    trackingReference: '',
    label: undefined,
    currentTrackingReference: '',
    showWebcam: false,
    trackingRefError: '',
    infoScan: undefined,
};
var Scan = /** @class */ (function (_super) {
    __extends(Scan, _super);
    function Scan() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _this = _super.apply(this, args) || this;
        _this.handleGetAlias = function () {
            var _a = _this.props, stationId = _a.stationId, getAliasAndAddToQueue = _a.getAliasAndAddToQueue, scanMode = _a.scanMode, playAliasSoundFromQueue = _a.playAliasSoundFromQueue;
            var trackingReference = _this.state.trackingReference;
            _this.setState(INITIAL_STATE);
            getAliasAndAddToQueue(trackingReference, stationId, scanMode, _this.setIframeContent).then(playAliasSoundFromQueue);
        };
        _this.handleChangeTrackingReference = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var previousTrackingReference, trackingReference;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        previousTrackingReference = this.state.trackingReference;
                        trackingReference = e.target.value;
                        return [4 /*yield*/, this.setState({
                                trackingReference: trackingReference,
                                trackingRefError: !this.validateTrackingRef(trackingReference)
                                    ? t('scanerr.validation.invalid.value')
                                    : '',
                            })];
                    case 1:
                        _a.sent();
                        if (previousTrackingReference.length === 0 &&
                            trackingReference.length > 8 &&
                            this.validateTrackingRef(trackingReference)) {
                            this.handleGetAlias();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        _this.setIframeContent = function (labelFile) {
            if (!labelFile)
                return;
            var blob = new Blob([labelFile], { type: 'application/pdf' });
            var data = window.URL.createObjectURL(blob);
            _this.setState({ label: data });
        };
        _this.handleLoadIframe = function (e) {
            var iframe = e.target;
            iframe.focus();
            iframe.contentWindow.print();
        };
        _this.uploadImage = function (imgSrc) { return __awaiter(_this, void 0, void 0, function () {
            var _a, stationId, getAliasAndAddToQueue, playAliasSoundFromQueue, ocrScan, scanMode, blob, ocr;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.props, stationId = _a.stationId, getAliasAndAddToQueue = _a.getAliasAndAddToQueue, playAliasSoundFromQueue = _a.playAliasSoundFromQueue, ocrScan = _a.ocrScan, scanMode = _a.scanMode;
                        if (!imgSrc) return [3 /*break*/, 4];
                        blob = base64toBlob(imgSrc);
                        return [4 /*yield*/, ocrScan(blob, stationId, this.setIframeContent)];
                    case 1:
                        _b.sent();
                        ocr = this.props.ocr;
                        if (!ocr.trackingReference) return [3 /*break*/, 3];
                        if (!confirm("Recognised tracking reference: " + ocr.trackingReference +
                            "\nConfidence level: " + ocr.confidenceLevel)) return [3 /*break*/, 3];
                        return [4 /*yield*/, getAliasAndAddToQueue(ocr.trackingReference, stationId, scanMode, this.setIframeContent)];
                    case 2:
                        _b.sent();
                        _b.label = 3;
                    case 3:
                        playAliasSoundFromQueue();
                        this.setState(INITIAL_STATE);
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.validateTrackingRef = function (trackingReference) { return TRACKING_REFERENCE_PATTERN.test(trackingReference); };
        _this.setInfoScan = function (infoScan) {
            _this.setState({ infoScan: infoScan });
        };
        _this.state = INITIAL_STATE;
        _this.uploadImage.bind(_this);
        _this.setIframeContent.bind(_this);
        return _this;
    }
    Scan.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevState.showWebcam && prevProps.scanMode !== this.props.scanMode) {
            this.toggleWebcam();
        }
    };
    Scan.prototype.toggleWebcam = function (e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        e === null || e === void 0 ? void 0 : e.target.blur();
        var showWebcam = this.state.showWebcam;
        this.setState({ showWebcam: !showWebcam });
    };
    Scan.prototype.render = function () {
        var _this = this;
        var _a = this.props, scanHistory = _a.scanHistory, stationId = _a.stationId, loading = _a.loading, currentScan = _a.currentScan, parcelCounterOutgoing = _a.parcelCounterOutgoing, parcelCounterReturn = _a.parcelCounterReturn, returnCompatible = _a.returnCompatible, error = _a.error, scanMode = _a.scanMode, ocrAllowed = _a.ocrAllowed, returnCodes = _a.returnCodes, bulkLabel = _a.bulkLabel, printBulkLabel = _a.printBulkLabel;
        var _b = this.state, trackingReference = _b.trackingReference, label = _b.label, showWebcam = _b.showWebcam, trackingRefError = _b.trackingRefError, infoScan = _b.infoScan;
        return (_jsxs("div", __assign({ className: 'scan' }, { children: [label !== null && label !== undefined && (_jsx("iframe", { title: 'print-label', onLoad: this.handleLoadIframe, src: label })), _jsx(ConfigBar, { includeMode: true }), error ? (_jsx(Error, { error: error })) : (_jsx(ScanInformation, { parcelCounter: parcelCounterOutgoing, parcelCounterReturn: parcelCounterReturn, returnCompatible: returnCompatible })), !stationId && _jsx("div", __assign({ className: 'config-message' }, { children: t('scan.choose.station') })), stationId &&
                    (scanMode === 'info' ? (_jsx(InfoScanForm, { stationId: stationId, initialTrackingReference: infoScan })) : (_jsxs("div", __assign({ className: "scan-wrapper".concat(error ? ' page-error' : '') }, { children: [_jsxs("div", __assign({ className: "alias-wrapper ".concat(showWebcam ? 'webcam-active' : '') }, { children: [_jsx("div", __assign({ className: 'alias-primary' }, { children: stationId && showWebcam ? (_jsx(OCRWebcam, { stationId: stationId, uploadImage: this.uploadImage })) : (_jsx(AliasCard, { alias: currentScan.alias, currentTrackingReference: currentScan.trackingReference, loading: loading, descriptionI18nKey: currentScan.descriptionI18nKey, descriptionValue: currentScan.descriptionValue, setInfoScan: this.setInfoScan })) })), _jsx("div", __assign({ className: "alias-history ".concat(showWebcam ? 'cam' : '') }, { children: scanHistory.map(function (scan, index) {
                                            return showWebcam && index === 0 ? (_jsx(_Fragment, { children: _jsx(AliasCard, { alias: currentScan.alias, currentTrackingReference: currentScan.trackingReference, loading: loading, descriptionI18nKey: currentScan.descriptionI18nKey, descriptionValue: currentScan.descriptionValue, setInfoScan: _this.setInfoScan }, index) })) : (_jsx(AliasCard, { alias: scan.alias, currentTrackingReference: scan.trackingReference, descriptionI18nKey: scan.descriptionI18nKey, descriptionValue: scan.descriptionValue, setInfoScan: _this.setInfoScan }, index));
                                        }) }))] })), _jsxs("form", { children: [_jsxs("div", __assign({ className: 'scan-form' }, { children: [_jsx("input", { type: 'text', name: 'trackingReference', maxLength: '30', placeholder: t('scan.tracking.reference.placeholder'), onChange: this.handleChangeTrackingReference, value: trackingReference, autoFocus: true }), _jsx("button", __assign({ type: 'submit', onClick: this.handleGetAlias, disabled: !trackingReference || trackingRefError }, { children: t('button.label.search') })), ocrAllowed && scanMode === 'return' && (_jsxs("button", __assign({ className: 'scan-button', type: 'button', onClick: this.toggleWebcam.bind(this) }, { children: [_jsx("i", { className: 'fas fa-camera' }), t('scan.mode.return.ocr')] })))] })), trackingRefError && _jsx("div", __assign({ className: 'scan-form-error' }, { children: trackingRefError })), returnCodes && scanMode === 'return' && trackingReference && (_jsx("div", __assign({ className: 'scan-return-codes' }, { children: t('return.code.suggestions.message') +
                                            fuzzySet(returnCodes)
                                                .get(trackingReference.toUpperCase(), [[]], 0.1)
                                                .map(function (o) { return o[1]; })
                                                .slice(0, 15)
                                                .join(', ') }))), scanMode === 'return' && (_jsx(BulkLabelInfo, { bulkLabel: bulkLabel, printBulkLabel: function () { return printBulkLabel(_this.setIframeContent); } }))] })] }))))] })));
    };
    return Scan;
}(Component));
var mapDispatchToProps = {
    getAliasAndAddToQueue: getAliasAndAddToQueue,
    playAliasSoundFromQueue: playAliasSoundFromQueue,
    ocrScan: ocrScan,
    printBulkLabel: printBulkLabel,
};
var mapStateToProps = function (state) {
    var station = state.stations.find(function (station) { return station.stationId === state.stationId; });
    return {
        loading: state.isLoading,
        error: state.error.scan,
        scanHistory: state.scan.history,
        currentScan: state.scan.current,
        ocr: state.scan.ocr,
        stationId: state.stationId,
        scanMode: state.scanMode,
        ocrAllowed: station && station.ocrActive,
        parcelCounterOutgoing: state.parcelCounter.outgoing,
        parcelCounterReturn: state.parcelCounter.return,
        returnCompatible: station ? station.returnCompatible : true,
        returnCodes: state.returnCodes.data,
        bulkLabel: state.bulkLabel,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Scan);
