var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as api from '../../api';
import { setLoader } from './loader';
import { resetErrors } from './resetErrors';
import store from '../store';
import { increaseParcelCounterReturn, increaseParcelCounterOutgoing } from './parcelCounter';
import { getBulkLabel } from './bulkLabel';
var aliasQueue = function () { return store.getState().scan.aliasQueue; };
var aliasSoundPlaying = function () { return store.getState().scan.aliasSoundPlaying; };
var bluetoothDelay = function () { return store.getState().user.bluetoothDelay; };
var mutedAliasSound = function () { return store.getState().user.mutedAliasSound; };
var speed = function () { return store.getState().user.speed; };
var language = function () { return store.getState().user.language; };
export var ALIAS_GET_SUCCEEDED = 'ALIAS_GET_SUCCEEDED';
export var ALIAS_GET_FAILED = 'ALIAS_GET_FAILED';
export var ALIAS_SOUND_PLAYING = 'ALIAS_SOUND_PLAYING';
export var ALIAS_SOUND_DONE = 'ALIAS_SOUND_DONE';
export var ALIAS_GET_SOUND_FAILED = 'ALIAS_GET_SOUND_FAILED';
export var OCR_GET_SUCCEEDED = 'OCR_GET_SUCCEEDED';
export var OCR_GET_FAILED = 'OCR_GET_FAILED';
export var playAliasSoundFromQueue = function () { return function (dispatch) {
    if (aliasSoundPlaying()) {
        setTimeout(function () { return dispatch(playAliasSoundFromQueue()); }, 100);
        return;
    }
    var alias = aliasQueue()[0].alias;
    dispatch({ type: ALIAS_SOUND_PLAYING });
    var aliasWords;
    if (alias.statusCode) {
        aliasWords = ['warning'];
    }
    else if (alias === 'OK' || mutedAliasSound()) {
        aliasWords = ['beep'];
    }
    else {
        aliasWords = alias.match(/[a-z]|[^a-z]+/gi).reduce(function (res, listObject) {
            if (+listObject && (+listObject > 100 || listObject.startsWith('0'))) {
                return __spreadArray(__spreadArray([], res, true), listObject.split(''), true);
            }
            return __spreadArray(__spreadArray([], res, true), [listObject], false);
        }, []);
    }
    var warningOrBeep = ['beep', 'warning'].includes(aliasWords[0]);
    if (bluetoothDelay())
        aliasWords.unshift('beep');
    Promise.all(aliasWords.map(function (word) {
        return api.sound.get(language(), word).then(function (base64Uri) {
            return new Promise(function (resolve) {
                var audio = new Audio();
                audio.src = base64Uri;
                audio.volume = +mutedAliasSound() && !warningOrBeep ? 0 : 1;
                audio.playbackRate = +speed();
                audio.addEventListener('canplaythrough', function () { return resolve(audio); });
            });
        });
    }))
        .then(function (audios) {
        return audios.reduce(function (promise, audio) {
            return promise.then(function () {
                return new Promise(function (resolve) {
                    audio.addEventListener('ended', function () { return resolve(); });
                    audio.play();
                });
            });
        }, Promise.resolve());
    })
        .catch(function (err) {
        dispatch({
            type: ALIAS_GET_SOUND_FAILED,
            data: err,
        });
    })
        .finally(function () {
        dispatch({
            type: ALIAS_SOUND_DONE,
        });
    });
}; };
var getAlias = function (trackingReference, stationId, scanMode, dispatch) {
    if (scanMode === 'return') {
        dispatch(increaseParcelCounterReturn());
        return api.alias.getReturn(trackingReference.toUpperCase(), stationId);
    }
    dispatch(increaseParcelCounterOutgoing());
    return api.alias.getOutgoing(trackingReference, stationId);
};
export var getAliasAndAddToQueue = function (trackingReference, stationId, scanMode, openPrintDialog) { return function (dispatch) {
    dispatch(setLoader(true));
    dispatch(resetErrors());
    return getAlias(trackingReference, stationId, scanMode, dispatch)
        .then(function (response) {
        if (response.labelSystem) {
            dispatch(getBulkLabel(response, openPrintDialog));
        }
        dispatch({
            type: ALIAS_GET_SUCCEEDED,
            data: {
                alias: response.alias,
                descriptionI18nKey: response.descriptionI18nKey,
                descriptionValue: response.descriptionValue,
                trackingReference: trackingReference,
            },
        });
    })
        .catch(function (err) {
        dispatch({
            type: ALIAS_GET_FAILED,
            data: {
                alias: err,
                trackingReference: trackingReference,
            },
        });
    })
        .finally(function () {
        dispatch(setLoader(false));
    });
}; };
export var ocrScan = function (img, stationId) { return function (dispatch) {
    dispatch(setLoader(true));
    dispatch(resetErrors());
    return api.alias
        .ocrRecogniseParcelNumber(img, stationId, dispatch)
        .then(function (response) {
        dispatch({
            type: OCR_GET_SUCCEEDED,
            data: {
                trackingReference: response.parcelNumber,
                confidenceLevel: response.confidence > 90 ? 'HIGH' : response.confidence > 50 ? 'MEDIUM' : 'LOW',
            },
        });
    })
        .catch(function (err) {
        dispatch({
            type: OCR_GET_FAILED,
            data: {
                error: err,
            },
        });
    })
        .finally(function () {
        dispatch(setLoader(false));
    });
}; };
